<script setup lang="ts">
const currentYear = new Date().getFullYear();
</script>

<template>
  <footer class="border-t border-zinc-100 dark:border-zinc-800 pt-14 pb-10 px-5">
    <div class="content-wrapper">
      <div class="md:w-3/4 mx-auto">
        <p class="text-center text-xl font-lyrical font-semibold">Made by musicians for musicians.</p>
        <Logo class="mt-16 mx-auto h-9" />
        <div class="mt-4 flex justify-center gap-3">
          <div><router-link :to="{ name: 'home' }">Home</router-link></div>
          <div><router-link :to="{ name: 'tos' }">Terms of Service</router-link></div>
          <div><router-link :to="{ name: 'privacy' }">Privacy Policy</router-link></div>
        </div>
        <p class="mt-5 text-xs text-center text-color-muted">© {{ currentYear }} LyricEdits. All rights reserved.</p>
      </div>
    </div>
  </footer>
</template>
